import axios from "axios";
import router from "./router/index";

const zainifauth = localStorage.getItem("zainifauth");
export function useAuth() {
  const isLoggedIn = () => {
    return !!zainifauth;
  };
  const clearToken = () => {
    localStorage.removeItem('zainifauth');
  };

  return { isLoggedIn, clearToken };
}

var domain = "https://zainifactory.code7x.com/api";
axios.defaults.baseURL = domain;
if (domain == "https://zainifactory.code7x.com/api") {
  axios.defaults.token = `Bearer ${zainifauth}`;
} else {
  axios.defaults.token;
}
console.log(axios.defaults);
const http = axios.create({
  baseURL: axios.defaults.baseURL,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
    Authorization: axios.defaults.token,
    // "Accept-Language": "ar",
  },
});
http.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (!navigator.onLine) {
      console.clear()
    }
    
    // Do something with response error
    if (error && error.response) {
     if(error.response.status == 401){
      router.push('/login');
    } else if (error.response.status == 404){
      router.push("/404")
    } 
   else {
      return Promise.reject(error.response);
    }
  }
  }
);
http.interceptors.request.use(
  (config) => {
    // Check if the token exists
    const token = axios.defaults.token;
    if (token) {
      // Add the token to the request header
      config.headers["Authorization"] = `Bearer ${zainifauth}`;
    }
    config.headers["Accept-Language"]=localStorage.getItem('lang')??"ar"
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
