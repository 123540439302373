<template>
    <v-container class="pt-12">
      <section>
        <h2 class="text-h4 font-weight-bold mb-6 ">
          {{ $t("partner") }}
        </h2>
        <v-window
          v-model="window"
          class="elevation-0 position-relative"
          :show-arrows="false"
        >
          <v-window-item v-for="(chunk, index) in chunkedReviews" :key="index">
            <v-row class="justify-center">
              <v-col
                v-for="(part, i) in chunk"
                :key="i"
                cols="12"
                md="3"
                sm="4"
                class="text-center"
              >
                <div>
                  <img :src="part.logo" alt="logo" width="100" height="100" style="object-fit: contain;">
                  <p class="text-body-1 mb-4">"{{ part.name }}"</p>
                </div>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </section>
    </v-container>
  </template>
  
  <script>
  import CrudDataService from "../../Service/CrudDataService";
  
  export default {
    data() {
      return {
        window: 0,
        partner: [],
        intervalId: null,  // For storing the interval ID
      };
    },
    computed: {
      chunkedReviews() {
        const groups = [];
        for (let i = 0; i < this.partner.length; i += 4) {
          groups.push(this.partner.slice(i, i + 4));
        }
        return groups;
      },
    },
    methods: {
      async productLatest() {
        let res = await CrudDataService.getAll("partners");
        this.partner = res.data.data;
      },
      startAutoSlide() {
        this.intervalId = setInterval(() => {
          // Move to the next window, and wrap around if necessary
          this.window = (this.window + 1) % this.chunkedReviews.length;
        }, 5000); // Change window every 3 seconds
      },
      stopAutoSlide() {
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      },
    },
    mounted() {
      this.productLatest();
       this.startAutoSlide();  // Start the auto-sliding on mount 
    },
    beforeDestroy() {
      this.stopAutoSlide();  // Clear the interval when component is destroyed
    },
  };
  </script>
  