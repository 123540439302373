<template>
  <v-row justify="space-between" class="mt-4">
    <v-col cols="12" lg="3" sm="4" xs="12" class="text-center">
      <v-avatar rounded="circle" color="#0e166833">
        <v-icon icon=" mdi-tag-multiple" color="#fff"></v-icon>
      </v-avatar>
      <h3 class="py-2">
        {{ $t('Valuable_offers') }}
      </h3>
      <p class="font-weight-light">
        {{ $t('Exclusive_offers_up_to') }}
      </p>
    </v-col>
    <v-col cols="12" lg="3" sm="4" xs="12" class="text-center">
      <v-avatar rounded="circle" color="#0e166833">
        <v-icon icon="mdi-account-circle" color="#fff"></v-icon>
      </v-avatar>
      <h3 class="py-2"> 
        {{$t('personal_account')}}
        </h3>
      <p class="font-weight-light">
            {{ $t('Daily_sales_delivery') }}
      </p>
    </v-col>
    <v-col cols="12" lg="3" sm="4" xs="12" class="text-center">
      <v-avatar rounded="circle" color="#0e166833">
        <v-icon icon="mdi-headphones" color="#fff"></v-icon>
      </v-avatar>
      <h3 class="py-2"> 
        {{ $t('Support_service') }}
        </h3>
      <p class="font-weight-light">
              {{$t('We_always_available_possible')}}
      </p>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
  }),
};
</script>
