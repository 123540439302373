<template>
  <v-container>
    <v-text-field
      density="compact"
      variant="solo"
      prepend-inner-icon="mdi-city"
      placeholder=" اسم العنوان "
      single-line
      hide-details
      rounded="0"
      class="search mb-3"
      v-model="formData.title"
    >
    </v-text-field>
    <v-text-field
      density="compact"
      variant="solo"
      prepend-inner-icon="mdi-map-marker"
      placeholder=" العنوان الجديد "
      single-line
      hide-details
      rounded="0"
      class="search mb-3"
      v-model="formData.address"
    >
    </v-text-field>
    <Multiselect
      label="name"
      class="mb-3"
      :searchable="true"
      :options="countries"
      placeholder="اختر البلد"
      v-model="onecountries"
      @change="addregions($event, countries)"
    />
    <Multiselect
      label="name"
      class="mb-3"
      :searchable="true"
      :options="regions"
      placeholder="اختر الاقليم "
      v-model="formData.region_id"
      @change="addcities($event, regions)"
    />
    <Multiselect
      label="name"
      class="mb-3"
      :searchable="true"
      :options="cities"
      placeholder="اختر المدينة "
      v-model="formData.city_id"
      @change="adddistrict($event, cities)"
    />
    <Multiselect
      label="name"
      class="mb-3"
      :searchable="true"
      :options="districta"
      placeholder="اختر المدينه "
      v-model="formData.district_id"
    />
    <div style="height: 250px">
      <l-map
        :zoom="zoom"
        :options="mapOptions"
        :key="0"
        :center="center"
        style="height: 100% !important; z-index: 00"
        @click="get"
      >
        <l-tile-layer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        ></l-tile-layer>
        <l-marker v-if="markerPosition" :lat-lng="markerPosition"></l-marker>
      </l-map>
    </div>
    <v-Switch
      label="عنوان افتراضي"
      color="primary"
      v-model="formData.is_default"
    ></v-Switch>
    <div class=" text-center">
        <v-btn
                  style="background-color: #0e1668"
                  class="rounded-0 text-white w-75"
                  @click="editaddres()"
                >
                {{ $t("Edit") }}{{ $t("address") }}
                </v-btn>
    </div>
    <v-snackbar
            v-model="showSnackbar"
            color="#0e1668"
          >
            {{ $t('Editing_successfully') }}
            <template v-slot:action="{ attrs }">
              <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
  </v-container>
</template>
<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import CrudDataService from "@/Service/CrudDataService";
import payment from "../../assets/image/payment.png";
import pay from "../../assets/image/pay.png";
import payments from "../../assets/image/payments.png";
import ppp from "../../assets/image/ppp.png";
import visa from "../../assets/image/visa.png";
import Multiselect from "@vueform/multiselect";
export default {
  data() {
    return {
      zoom: 4,
      center: [24.774265, 46.738586],
      mapOptions: {
        zoomControl: true,
        scrollWheelZoom: false,
        touchZoom: false,
        doubleClickZoom: true,
        boxZoom: false,
      },
      countries: [],
      cities: [],
      regions: [],
      districta: [],
      payment,
      pay,
      payments,
      ppp,
      visa,
      message: "",
      dialog: false,
      showDiv: null,
      address: "",
      items: [],
      showSnackbar: false,
      formData: {
        address: "",
        title: "",
        is_default: false,
        latitude: "",
        longitude: "",
        city_id: "",
        region_id: "",
        district_id: "",
      },
      dataitem: [],
      address_id: "",
      payment_method_id: 1,
      note: "",
      expected_delivery_date: null,
      onecountries: 1,
    };
  },
  components: {
    Multiselect,
    LMap,
    LTileLayer,
    LMarker,
  },
  methods: {
    async country() {
      const result = await CrudDataService.getAll("countries");
      this.countries = result.data.data.map((country) => ({
        value: country.id,
        name: country.name,
        regions: country.regions,
      }));
      this.addregions()
    },
    addregions(events, countries) {
      this.countries.forEach((ele) => {
        console.log(ele,"okkook");
        if (events === ele.value || this.onecountries === ele.value) {
          this.regions = ele.regions.map((reg) => ({
            value: reg.id,
            name: reg.name,
            cities: reg.cities,
          }));
          console.log(this.regions);
        }
      });
      this.addcities()
    },
    addcities(events, regions) {
        this.regions.forEach((ele) => {
        if (events === ele.value|| this.formData.region_id === ele.value) {
          this.cities = ele.cities.map((city) => ({
            value: city.id,
            name: city.name,
            districts: city.districts,
          }));
        }
      });
      this.adddistrict()
    },
    adddistrict(events, cities) {
        this.cities.forEach((ele) => {
        if (events === ele.value|| this.formData.city_id === ele.value) {
          this.districta = ele.districts.map((dist) => ({
            value: dist.id,
            name: dist.name,
          }));
        }
      });
    },
    async allcountries() {
      let country = await CrudDataService.getAll("countries");
      console.log(country.data.data);
    },
    addone(id) {
      this.address_id = id;
    },

    async oneaddres() {
      let res = await CrudDataService.get(
        `user/addresses`,
        `${this.$route.params.id}`
      );
      this.items = res.data.data;
      this.formData.address = res.data.data.address;
      this.formData.title = res.data.data.title;
      this.formData.latitude = res.data.data.latitude;
      this.formData.longitude = res.data.data.longitude;
      this.formData.city_id = res.data.data.city_id;
      this.formData.district_id = res.data.data.district_id;
      this.formData.region_id = res.data.data.region_id;

      console.log(this.formData);
    },
    async editaddres() {
      let res = await CrudDataService.update("user/addresses",`${this.$route.params.id}`, this.formData);
      if (res.data.success) {
        this.oneaddres();
        this.showSnackbar = true;
      }
    },

    get(event) {
      this.formData.latitude = event.latlng.lat;
      this.formData.longitude = event.latlng.lng;
    },
  },
  computed: {
    markerPosition() {
      if (this.formData.latitude !== null && this.formData.longitude !== null) {
        return [this.formData.latitude, this.formData.longitude];
      }
      return null;
    },
  },

  mounted() {
    this.oneaddres();
    this.country();
    this.addregions();
  },
};
</script>
