<template>
    <v-container>
        <section class="pagestyle">
          <v-breadcrumbs :items="items">
      <template v-slot:title="{ item }">
        {{ $t(item.title) }}
      </template>
    </v-breadcrumbs>
    <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-if="loading"
   >

<section class="cate">
</section>
    <img :src="info" width="250">

    </section>
    
   <section v-else>
    <section v-if="content">
        <h1 class='text-center mb-5'>{{ content.title }}</h1>
      <div v-html="content.content"></div>
    </section>
    <section v-else>
      <v-card class="hint my-7 py-2" elevation="0">
        <v-card-text>
          {{ $t("nothing") }}
          {{ items[1].title }}
          
        </v-card-text>
      </v-card>
    </section>
   </section>
   
  </section>
    </v-container>

</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import info from "../../assets/image/Into.gif"

export default {
  data: () => ({
info,
    loading:false,
    content: "",
    items: [
      {
        title: "Home",
        disabled: false,
        href: "/",
      },
      {
        title: "",
        disabled: false,
      },
    ],
  }),
  beforeRouteUpdate(to, from, next) {
    // This guard is called when the route changes, but this component is reused
    this.allpages(to.params.id);
    next();
  },
  methods: {
 
    async allpages(id) {
      this.loading = true; // Start loading
      try {
        let res = await CrudDataService.get("pages",`${id}`);
      this.content =  res.data.data
      this.items[1].title=this.content.title
      // res.data.data.forEach((element) => {
      //   if (element.slug == this.$route.params.slug) {
      //       this.items[1].title=element.title

      //     this.content = element;
      //   }
      // });
 
}catch (error) {
console.error("Failed to fetch data:", error);
// Handle error
} finally {
this.loading = false; // End loading regardless of success or failure
}
     
    },
  },
  mounted() {
    this.allpages(this.$route.params.id);
  },
};
</script>

<style>
.v-breadcrumbs {
  color: #8b96a5;
}
.pagestyle p{
  margin: 5px 0;
}
.pagestyle ul {
    padding: auto;
    padding-inline-start: 40px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
</style>

<style scoped>
.hint {
  background: #0e166833;
  color: white;
  text-align: center;
}
.hint .v-card-text {
  font-size: 18px;
}
</style>
