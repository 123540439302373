<template>
  
    <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-if="loading"
   >

<section class="cate">
</section>
    <img :src="info" width="250">

    </section>
  <section class="my-7" v-else>
    <h3 class="mb-7">{{ $t("Previous_orderes") }}</h3>
    <section class=" rounded" v-if="items.length > 0">
      <div  
        >
        <v-card
        v-for="ones in items"
        class="pt-4 pb-3 px-4 cardorder mb-5"
        :key="ones.id"
        style="background-color: transparent"
        elevation="0"
        @click="order(ones.order_id)"
      >

      {{ ones.order_id }}
      <h4>
        {{ $t('Order_details') }}
      </h4>
      <ul class="pb-3" style="list-style: none;">
                <li >
                  <span class="font-weight-bold">
                    {{ $t('status') }}:
                  </span>
                {{ ones.translated_status }}
                </li>
                <li >
                  <span class="font-weight-bold">
                    {{ $t('Quantity') }}:
                  </span>
                {{ ones.items.quantity }}
                </li>
                <li>
                  <span class="font-weight-bold">
                    {{ $t('orderdate') }}:
                  </span>
                {{ ones.created_at.slice(0,10)}}

                </li>
                <li>
                  <span class="font-weight-bold">
                    {{ $t('ordernum') }}:
                  </span>
                  {{ ones.order_id }}
                </li>
                <li>
                  <span class="font-weight-bold">
                    {{ $t('discount') }}:
                  </span>
                  {{ones.coupon_discount}}
                </li>
                <li>
                  <span class="font-weight-bold">
                    {{ $t('shipping') }}:
                  </span>
                  {{ones.shipping}}
                </li>
      </ul>
      <div class="totalorder py-3">
        <span class="font-weight-bold text-red">
          {{ $t('Total_order') }} :
        </span>
 {{ ones.total }}
      </div>
      </v-card>
       
     
      </div>
      <div class="pa-5 pt-2 d-flex justify-space-between align-items-center">
        <!-- <v-btn
          class="text-red"
          variant="outlined"
          style="border: 1px solid#DEE2E7"
          @click="removeall()"
        >
          {{ $t("delete_all") }}
        </v-btn> -->
        <v-btn
          style="background-color: #0e1668; color: white"
          width="165"
          variant="text"
          class="rounded-0"
          @click="backshop"
        >
          {{ $t("Back_shopping") }}
          <v-icon icon="mdi-arrow-left" class="ms-2"></v-icon>
        </v-btn>
      </div>
    </section>
    <section v-else>
      <v-card class="hint py-2" elevation="0">
        <v-card-text>
          {{ $t("nothing") }}
          {{ $t("Previous_orderes") }}
        </v-card-text>
      </v-card>
    </section>
  </section>
</template>
<script>
import CrudDataService from '@/Service/CrudDataService';
import info from "../../assets/image/Into.gif"

export default {
  data() {
    return {
      info,
    loading:false,
      items: [
      ],
    };
  },
  methods: {
    order(id) {
      this.$router.push({ name: "Oneorder",params:{id} });
    },
  async orders(){
    this.loading = true; // Start loading
      try {
   let res= await CrudDataService.getAll("orders");
this.items=res.data.data.data;
console.log(this.items);
}catch (error) {
console.error("Failed to fetch data:", error);
// Handle error
} finally {
this.loading = false; // End loading regardless of success or failure
}
    },
    backshop(){
        this.$router.push({ name: "Product" });
    },
    detail(id) {
      this.$router.push({ name: "Detail",params:{id} });
    },
    remove(itemId) {
       CrudDataService.delete("cart",itemId);
      this.items = this.items.filter((item) => item.id !== itemId);
    },
    removeall() {
       CrudDataService.delete(`cart`);
      this.items = [];
    },
  },
  mounted(){
    this.orders()
  }
};
</script>
<style>
.selectele .v-text-field .v-input__details {
  display: none;
}
</style>
<style scoped>
.cardorder{
  background:#f6f4f9 !important;
  border: 1px solid #d5b9ff;
  border-radius: 19px;
  box-shadow: 6px 2px 9px #dad6d6 !important;
}
.hint {
  background: #0e166833;
  color: white;
  text-align: center;
  margin-bottom: 12rem !important;
  margin-top: 5rem !important;
}
.hint .v-card-text {
  font-size: 18px;
}
.totalorder{
  border-top: 1px solid #d5b9ff;
}
</style>
