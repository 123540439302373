<template>
  
    <section style="background: #eeeeee"  v-if="loading" >
      <v-container>
        <Header />
        <Categories />
        <!-- <Offers /> -->
        <Latestpackages />
        <Slide/>
        <Most/>
        <SlideBanner/>
        <Product/>
        <Descution/>
        <Customer/>
        <!-- <Secoffer/> -->
      </v-container>
   

    </section>
    <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-else
   >

<section class="cate">
</section>
      <!-- <v-progress-circular
      indeterminate
      color="#d6487c8a"
    ></v-progress-circular> -->
    <img :src="info" width="250">

    </section>
 
</template>

<script>
import { defineComponent } from "vue";
import info from "../assets/image/Into.gif"
// Components
import Header from "../components/HOME/Header.vue";
import Categories from "../components/HOME/Categories.vue";
import Offers from "../components/HOME/Offers.vue";
import Latestpackages from "../components/HOME/Latestpackages.vue";
import Most from "../components/HOME/Most.vue";
import Product from "../components/HOME/Product.vue";
import Slide from "../components/HOME/Slide.vue";
import SlideBanner from "../components/HOME/SlideBanner.vue";
import Customer from "../components/HOME/Customer.vue";
import Descution from "../components/HOME/Descution.vue";
import Secoffer from "../components/HOME/Secoffer.vue";
import CrudDataService from "@/Service/CrudDataService";

export default defineComponent({
  name: "HomeView",
data(){
  return{
loading:false,
info,
  }
},
  components: {
    Header,
    Categories,
    Offers,
    Latestpackages,
    Most,
    Product,
    Slide,
    SlideBanner,
    Descution,
    Secoffer,
    Customer
  },
  async mounted() {
    
      await CrudDataService.getAll("partners");
      setTimeout(() => {
            this.loading=true
          }, 0);
  },
});
</script>
