<template>
  <section
    class="mt-8 descution py-md-16 py-1"
    data-aos-offset="200"
    :data-aos-delay="300"
    data-aos="fade-up"
  >
    <v-row>
      <v-col cols="12" md="5" class="py-0">
        <div
          class="d-flex flex-column ps-md-10 ps-1 py-5 h-100"
          style="color: #fff"
          height="100%"
        >
          <p style="font-size: 26px">
            {{ $t("easy_way_contact") }}
          </p>
          <!-- <p class="font-weight-light">
                هذا النص هو مثال لنص يمكن استبداله هذا النص هو مثال لنص يمكن
                استبداله هذا النص هو مثال لنص يمكن استبداله
              </p> -->
        </div>
      </v-col>
      <v-col cols="12" md="7" class="py-0">
        <v-card class="mx-auto py-5 px-6" max-width="500">
          <h5 class="text-h5 pb-2">
            {{ $t("What_inquiry") }}
          </h5>
          <v-text-field
            type="text"
            density="compact"
            variant="solo"
            :placeholder="$t('name')"
            single-line
            hide-details
            rounded="0"
            class="border mb-3 contact"
            v-model="formData.name"
          >
          </v-text-field>
          <v-text-field
            type="email"
            density="compact"
            variant="solo"
            :placeholder="$t('email')"
            single-line
            hide-details
            rounded="0"
            class="border mb-3 contact"
            v-model="formData.email"
          >
          </v-text-field>
          <v-text-field
            type="tel"
            density="compact"
            variant="solo"
            :placeholder="$t('phone_number')"
            single-line
            hide-details
            rounded="0"
            class="border mb-3 contact tel"
            v-model="formData.phone"
          >
          </v-text-field>

          <v-textarea
            density="compact"
            variant="solo"
            counter
            :placeholder="$t('More_details')"
            class="border mb-3 contact"
            hide-details
            v-model="formData.message"
            rows="2"
          ></v-textarea>
          <v-btn
            style="background-color: #0e1668"
            class="text-white ms-auto me-12 elevation-0 rounded-0"
            @click="contact"
          >
            {{ $t("Send") }}
          </v-btn>
          <div v-if="messageerror" class="text-red pt-2">
            {{ messageerror }}
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showSnackbar"
      color="#0e1668"
    >
{{ $t('Message_sent_successfully') }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import img from "../../../src/assets/image/Mask.png";
export default {
  data() {
    return {
      mask: img,
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      showSnackbar: false,
      messageerror: "",
    };
  },
  methods: {
    async contact() {
      let res = await CrudDataService.create("contact-us", this.formData).then((response)=>{
        console.log(response.data.success);
        this.showSnackbar = true;
        (this.formData.name = ""),
          (this.formData.email = ""),
          (this.formData.phone = ""),
          (this.formData.message = "");
        this.messageerror = "";

      }).catch((error)=>{
        this.messageerror = error.data.message;

      })
      // console.log(res.data.success);
      // if (res.data.success) {
      //   this.showSnackbar = true;
      //   (this.formData.name = ""),
      //     (this.formData.email = ""),
      //     (this.formData.phone = ""),
      //     (this.formData.message = "");
      // } else {
      // }
    },
  },
};
</script>
<style scoped>
.descution {
  background: url("../../assets/image/Mask.png");
  background-size: cover;
}
</style>
<style>
.contact .v-field--variant-solo,
.contact .v-field--variant-solo-filled {
  box-shadow: none !important;
}
.tel input {
  text-align: end !important;
}
.v-breadcrumbs {
  color: #8b96a5;
}
</style>
