<template>
  <section class="sideone position-relative">
    <v-row style="align-items: center" justify="space-between">
      <v-col cols="12" md="6" class="px-0">
        <v-img :src="icon" class="pa-3 bg-sec">
          <img :src="logo" alt="" />
          <div
            class="sec-login"
            style="
              width: 400px;
              margin: auto;
              height: 50%;
              display: grid;
              place-items: center start;
            "
          >
            <div style="width: 100%">
              <h3 class="mb-3">
                {{ $t("DeleteAcount") }}
              </h3>
              <v-text-field
                type="text"
                variant="solo"
                :placeholder="$t('phone_number') + ' / ' + $t('email')"
                class="mb-5 tel"
                v-model="loginForm.phone"
                hide-details
              ></v-text-field>

              <div class="text-red mb-4">
                {{ errorMessage ? errorMessage : "" }}
              </div>
              <!-- <v-btn v-bind="props" text="Open Dialog"> </v-btn> -->
              <v-btn
                width="100%"
                height="50px"
                class="text-white"
                color="#252a31"
                style="box-shadow: rgb(77 71 195 / 20%) 0px 2px 16px 0px"
                @click="handleLogin"
              >
                {{ $t("DeleteAcount") }}
              </v-btn>
              <!-- <textotp/> -->
              <v-dialog width="500" v-model="openotp">
                <v-card
                  class="py-11 px-8 text-center ma-0 over"
                  elevation="12"
                  max-width="500"
                  width="100%"
                  v-if="otpmessage"
                >
                  <div style="font-size: 19px; font-weight: 500">
                    {{ otpmessage }}
                    {{ $t("OTP_phone") }}
                  </div>

                  <v-sheet color="surface">
                    <div
                      class="otp-inputs d-flex justify-center align-center mt-5"
                    >
                      <v-text-field
                        v-for="(input, index) in otpInputs"
                        :key="index"
                        v-model="otpInputs[index]"
                        :input-attrs="{
                          inputmode: 'numeric',
                          maxlength: 1,
                          pattern: '\\d*',
                        }"
                        @input="handleInput(index)"
                        outlined
                        dense
                        class="otp-field"
                        ref="otpInput"
                        hide-details
                        variant="solo"
                        type="tel"
                      ></v-text-field>
                    </div>
                    <!-- <v-otp-input
                        v-model="otp"
                        length="4"
                        type="tel"
                        variant="solo"
                        inputmode="number"
  
                      ></v-otp-input> -->
                  </v-sheet>
                  <div class="text-red my-4">
                    {{ errorMessage ? errorMessage : "" }}
                  </div>
                  <v-btn
                    class="my-4 mx-auto"
                    color="#0e1668"
                    height="40"
                    :text="$t('Verify')"
                    variant="flat"
                    width="50%"
                    @click="handleverify"
                  ></v-btn>
                </v-card>
              </v-dialog>
            
            </div>
          </div>
          <div class="w-100 d-flex justify-end arrowimg">
            <img :src="arrow" alt="" />
          </div>
        </v-img>
      </v-col>
      <div
        class="position-absolute logimg"
        style="right: 40%; top: 25px; width: 280px; z-index: 111"
      >
        <v-img :src="Vect">
          <div
            class="d-flex justify-center align-center w-100 h-100"
            style="font-size: 28px; font-weight: 500"
          >
            <p class="text-center">{{ $t("DeleteAcount") }}</p>
          </div>
        </v-img>
        <!-- <img :src="Vect"  alt="" /> -->
      </div>

      <v-col cols="12" md="5" class="px-0">
        <v-img :src="Loginamico" class="imgsection"> </v-img>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import logo from "../assets/image/logo.png";
import image from "../assets/image/Vector.png";
import Loginamico from "../assets/image/Loginamico.svg";
import Vect from "../assets/image/Vect.png";
import arrow from "../assets/image/Grouparrow.png";
// import textotp from "./textotp.vue";
import CrudDataService from "@/Service/CrudDataService";
export default {
  data() {
    return {
      otp: "",
      logo: logo,
      icon: image,
      Loginamico: Loginamico,
      Vect: Vect,
      arrow: arrow,
      loginForm: {
        phone: "",
      },
      invalidPhoneNumber: false,
      validationMessage: "",
      errorMessage: "",
      otpmessage: "",
      openotp: false,
      otpInputs: ["", "", "", ""], // Array to hold the values of each input
    };
  },
  components: {},
  methods: {
    handleInput(index) {
      
      const nextIndex = index + 1;
      // Ensure input is numeric
      this.otpInputs[index] = this.otpInputs[index].replace(/[^0-9]/g, "");
      // If there's a next input, move focus to it
      if (index < this.otpInputs.length - 1) {
        this.$refs.otpInput[index + 1].focus();
      }
    },
    async handleLogin() {
      await CrudDataService.create(`delete-account`, this.loginForm)
        .then((response) => {
          this.otpmessage = response.data.message;
          this.openotp = true;
          this.errorMessage = "";
        })
        .catch((error) => {
          this.errorMessage = error.data.message;
        });
    },
    async handleverify() {
      const otp = this.otpInputs.join("");
      await CrudDataService.create(`verify-delete-account`, {
        phone: this.loginForm.phone,
        otp: otp,
      })
        .then((response) => {
          this.$router.push({ name: "home" });
          localStorage.setItem("zainifauth", response.data.data.token);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          this.errorMessage = error.data.message;
        });
    },
   
  },
  mounted() {},
};
</script>
<style>
.otp-field {
  margin-right: 5px;
  margin-left: 5px;
  max-width: 60px; /* Adjust width of each input field */
}
.otp-field .v-text-field .v-field--no-label input,
.otp-field .v-text-field .v-field--active input {
  text-align: center;
}
.otp-field input {
  text-align: center !important;
}
[dir="ltr"] .bg-sec .v-img__img--contain {
  transform: rotateY(180deg);
}
.tel input {
  text-align: start !important;
}
.sideone .v-input--density-default .v-field--variant-outlined,
.sideone .v-input--density-default .v-field--single-line,
.sideone .v-input--density-default .v-field--no-label {
  background: #f0efff;
  color: #a7a3ff;
}
@media (max-width: 991.98px) {
  .sideone .imgsection {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .sideone .logimg {
    display: none;
  }
  .sideone .arrowimg {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  .sideone .sec-login {
    width: 90% !important;
  }
  .sideone .bg-sec {
    height: 130vh;
    object-fit: cover !important;
  }

  .bg-sec .v-img__img--contain {
    object-fit: cover !important;
  }
}
.v-overlay__scrim {
  background: rgba(187, 134, 252, 0.762);
}
</style>
