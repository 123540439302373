<template>
  <v-container>
    <section class="position-relative" style="height: 100vh;display: grid;
    place-items: center;"
    v-if="loading"
   >

<section class="cate">
</section>
    <img :src="info" width="250">

    </section>
  <section class="my-7" v-else>
    <h3 class="mb-7">{{ $t("Previous_orderes") }}</h3>
    <section class="bg-white rounded" v-if="items.length > 0">
      <div  v-for="(ones,i) in items"
        :key="i">
        <v-card
        class="pa-3"
        v-for="item in ones.items"
        :key="item.id"
        style="background-color: transparent"
        elevation="0"
      >
        <v-row>
          <v-col cols="12" sm="2" xs="12">
            <v-img
              :src="item.product.cover_image"
              height="200"
              cover
              class="rounded"
            ></v-img>
          </v-col>
          <v-col cols="12" sm="8" xs="12">
            <div width="80%">
              <p @click="detail(item.product.id)" style="cursor: pointer">
                {{ item.product.name }}
              </p>
              <div 
              class="d-flex flex-sm-row flex-column"
              v-if="ones.status == 'completed'"
              >
                <v-rating
                    :model-value="item.product.rating"
                    @update:model-value="
                      updateRating(item.product.id, $event)
                    "
                    color="amber"
                    density="compact"
                    hover
                    size="small"
                    class="px-0"
                  ></v-rating>
                  <p style="color: #ff9017" >
                    {{ item.product.rating }}
                  </p>
              </div>
           
              <!-- <v-btn
                class="text-red mt-3 rounded-0"
                variant="outlined"
                style="border: 1px solid#DEE2E7"
                @click="remove(item.id)"
              >
                {{ $t("delete") }}
              </v-btn> -->
              <!-- <v-btn
                style="background-color: #252a31; color: white"
                class="rounded-0 mt-3 ms-2"
                variant="text"
              >
                {{ $t('Reorder') }}
              </v-btn> -->
            </div>
          </v-col>
          <v-col cols="12" sm="2" xs="12">
            <v-card-title>
              
              {{ ones.total }}
             {{ $t('rs') }}
            </v-card-title>
            <div class="d-flex justify-space-between align-center">
              <span>{{ $t("Quantity") }}</span>

              <input
                type="number"
                id="quantity"
                min="1"
                class="form-control w-25 text-center"
                v-model.number="item.quantity"
                disabled
              />
            </div>
          </v-col>
        </v-row>
        <v-divider class="mx-4 mb-1 mt-8"></v-divider>
      </v-card>
     
      </div>
      <div class="pa-5 pt-2 d-flex justify-space-between align-items-center">
        <!-- <v-btn
          class="text-red"
          variant="outlined"
          style="border: 1px solid#DEE2E7"
          @click="removeall()"
        >
          {{ $t("delete_all") }}
        </v-btn> -->
        <v-btn
          style="background-color: #0e1668; color: white"
          width="165"
          variant="text"
          class="rounded-0"
          @click="backshop"
        >
          {{ $t("Back_shopping") }}
          <v-icon icon="mdi-arrow-left" class="ms-2"></v-icon>
        </v-btn>
      </div>
    </section>
    <section v-else>
      <v-card class="hint py-2" elevation="0">
        <v-card-text>
          {{ $t("nothing") }}
          {{ $t("Previous_orderes") }}
        </v-card-text>
      </v-card>
    </section>
  </section>
  </v-container>
   
</template>
<script>
import CrudDataService from '@/Service/CrudDataService';
import info from "../../assets/image/Into.gif"

export default {
  data() {
    return {
      info,
    loading:false,
      items: [
      ],
    };
  },
  methods: {
    async updateRating(productId, rating) {
      console.log(productId, rating);
      // Call your API to update the rating
      try {
        await CrudDataService.create(`products/${productId}/reviews`, {
          rating: rating,
        });
        this.orders()
        // Handle success - e.g., show a message or update local state
      } catch (error) {
        // Handle error - e.g., show an error message
        console.error("Error updating rating:", error);
      }
    },
  async orders(){
    this.loading = true; // Start loading
      try {
   let res= await CrudDataService.getAll(`orders?filter[order_id]=${this.$route.params.id}`);
this.items=res.data.data.data;
console.log(this.items);
}catch (error) {
console.error("Failed to fetch data:", error);
// Handle error
} finally {
this.loading = false; // End loading regardless of success or failure
}
    },
    backshop(){
        this.$router.push({ name: "Product" });
    },
    detail(id) {
      this.$router.push({ name: "Detail",params:{id} });
    },
    remove(itemId) {
       CrudDataService.delete("cart",itemId);
      this.items = this.items.filter((item) => item.id !== itemId);
    },
    removeall() {
       CrudDataService.delete(`cart`);
      this.items = [];
    },
  },
  mounted(){
    this.orders()
  }
};
</script>
<style>
.selectele .v-text-field .v-input__details {
  display: none;
}
</style>
<style scoped>
.cardorder{
  background:#f6f4f9;
  border: 1px solid #d5b9ff;
  border-radius: 19px;
}
.hint {
  background: #0e166833;
  color: white;
  text-align: center;
  margin-bottom: 12rem !important;
  margin-top: 5rem !important;
}
.hint .v-card-text {
  font-size: 18px;
}
</style>
