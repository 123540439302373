<template>
  <v-card class="hint mb-10 mt-4 py-2" elevation="0">
    <v-card-text>
      {{ $t('Save_favorite_thing') }}
    </v-card-text>
  </v-card>
</template>
<style scoped>
.hint {
  background: #0e166833;
  color: white;
  text-align: center;
}
.hint .v-card-text {
    font-size: 18px;
}
</style>
